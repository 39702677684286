import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const DBManagerPage = () => (
  <div>
    <SEO 
        title="Database Manager - Sheep Hosting Bay" 
        description="The easiest path to manage your personal MySQL and PgSQL databases"
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title"> <span className="block text-indigo-600 xl:inline">Databases</span> Manager <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">The easiest path to manage your personal MySQL and PgSQL databases</p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a 
                      href="https://demo.hepsia.com/filemanager/?auto_login=true" 
                      rel="noreferrer" 
                      target="_blank" 
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                      View Demo
                      </a>
                  </div>
              </div>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <p className="m-0">
                        <img src="https://sheephostingbay.duoservers.com/template2/img/databases-manager-banner.webp" alt="Sheephostingbay Web control panel"/>
                    </p>
                  </div>
              </div>
              
            </div>
        </div>
        
    </div>

    <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                 <p className="section-after-title">
                 <span role="img" aria-label="emoji">🦄</span> From within the Databases Manager incorporated into the Web Control Panel, it will be 
                 easier to build new MySQL and PgSQL databases immediately! Furthermore, you will get direct access to the administrator area 
                 software tools (phpMyAdmin and phpPgAdmin, respectively), so you can conveniently modify the database you want.
                </p>
              
            </div>
        </div>
        
    </div>

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/an-easy-to-use-interface.webp" className="w-60" alt="Clean and Hassle–free Interface" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Clean and Hassle–free Interface</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Command all of your databases from a single place<br/><br/>
                                    The Databases Manager inside the Web Control Panel features a robust yet user–friendly user interface. 
                                    It’s possible to set up a new MySQL or PostgreSQL database simply by entering a user name and 
                                    password. In addition, you may create a backup file or modify the security password of any 
                                    database with only a mouse click.
                                    <br/><br/>
                                    From the Databases Manager you will get direct access to the administrator area for each of your 
                                    databases, meaning you can make convenient adjustments in the event you have to.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Support for PgSQL</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Guaranteed safety for your personal databases<br/><br/>
                                    PgSQL databases are less common in comparison to the common MySQL databases. Nevertheless, they’re a 
                                    preferred selection for web developers who seek the best protection for their web sites and web 
                                    applications. Due to the user–friendly Databases Manager integrated into the Sheephostingbay 
                                    Control Panel, you can administer your PostgreSQL databases with a simple mouse click.
                                    <br/><br/>
                                    You are going to find PgSQL databases provided automatically inside the best website hosting packages. 
                                    In case you are with a standard hosting package, you can ask for PgSQL databases to be included in 
                                    your website hosting account as upgrades.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/pgsql-support.webp" className="w-60" alt="Support for PgSQL" />
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/innodb-databases.webp" className="w-60" alt="InnoDB Databases" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">InnoDB Databases</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    The fresh face of MySQL<br/><br/>
                                    The MySQL databases on our web servers use the most current build of the InnoDB storage engine. InnoDB is 
                                    built to ensure the highest possible efficiency for web sites that work with massive volumes of data. It 
                                    features CPU effectiveness that could not be equalled by any other different disk–based relational 
                                    database engines.
                                    <br/><br/>
                                    InnoDB is ACID–compliant and gives complete transaction support, which means that you can easily 
                                    update/insert/create/alter/drop a couple of times for one "operation" of your application. 
                                    In addition, it makes use of row–level locking and not MyISAM’s table–level locking, that 
                                    raises multi–user concurrency and performance.
                                    </div>
                                </div>
                            </div>
                        </div>   

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Detailed Database Stats</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Detailed statistics for all your databases<br/><br/>
                                    Tracking the load accumulated by the databases within your active site assists you to stop all overload 
                                    difficulties that could slow it down and ward off website visitors. For that reason, we’ve provided a 
                                    in–depth Database Stats tool directly into the Web Control Panel. There you can see comprehensive 
                                    information about the arriving requests to your databases for each and every hour, day and month.
                                    <br/><br/>
                                    Depending on the quantity of day–to–day and hourly queries, it will be easy to find out what databases 
                                    are used the most and consider procedures to enhance their work.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/detailed-database-stats.webp" className="w-60" alt="Detailed Database Stats" />
                            </div>
                        </div>  

                  </div>
              </div>
          </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default DBManagerPage
